import React, { useState } from "react";
import { Form, TextInput, TextArea, Button } from "carbon-components-react";
import { InlineNotification } from "carbon-components-react";

const ContactForm = () => {
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [emailError, setEmailError] = useState({});
  const [phoneError, setPhoneError] = useState({});
  const [messageError, setMessageError] = useState({});
  const [toast, setToast] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (toast) {
      return;
    }
    const isValid = formValidation();
    async function postData(url = "", data = {}) {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      return response.json();
    }

    const data = {
      email: email,
      message: message,
      phone: phone,
    };

    if (isValid) {
      postData("/api/contact", data);
      setEmail("");
      setMessage("");
      setPhone("");
      setToast(true);
    }
  };

  const formValidation = () => {
    const emailError = {};
    const phoneError = {};
    const messageError = {};
    let isValid = true;

    if (!email.trim().length) {
      emailError.required = "Email is required";
      isValid = false;
    }

    if (email.trim().length) {
      if (
        !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          email
        )
      ) {
        emailError.invalid = "Email is invalid. Example: user@email.com";
        isValid = false;
      }
    }

    if (phone.trim().length) {
      if (!/^\+(?:[0-9] ?){6,14}[0-9]$/.test(phone)) {
        phoneError.invalid = "Phone number is invalid. Example: +601788889999";
        isValid = false;
      }
    }

    if (!message.trim().length) {
      messageError.required = "Message is required";
      isValid = false;
    }
    if (message.trim().length < 36) {
      messageError.tooShort =
        "Message is too short. Message to be at least 36 characters";
      isValid = false;
    }
    setEmailError(emailError);
    setMessageError(messageError);
    setPhoneError(phoneError);
    return isValid;
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <div style={{ marginBottom: "2rem" }}>
          <TextInput
            helperText="Example: user@email.com"
            id="email"
            invalidText={
              emailError.invalid ? emailError.invalid : emailError.required
            }
            labelText="Email Address"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            invalid={emailError.required || emailError.invalid}
          />
        </div>
        <div style={{ marginBottom: "2rem" }}>
          <TextInput
            helperText="Example: +60155550000"
            id="phone"
            invalidText={phoneError.invalid}
            labelText="Phone Number (Optional)"
            onChange={(e) => setPhone(e.target.value)}
            value={phone}
            invalid={phoneError.invalid}
          />
        </div>
        <div style={{ marginBottom: "2rem" }}>
          <TextArea
            cols={50}
            helperText="Enter your message here and we will get back to you as soon as we can."
            id="message"
            labelText="Your message"
            rows={4}
            invalidText={
              messageError.required
                ? messageError.required
                : messageError.tooShort
            }
            onChange={(e) => setMessage(e.target.value)}
            value={message}
            invalid={messageError.required || messageError.tooShort}
          />
        </div>

        {toast && (
          <InlineNotification
            kind="success"
            iconDescription="Close notification"
            subtitle="Your form submission is received successfully."
            title="Thank you. "
            onCloseButtonClick={() => setToast(false)}
          />
        )}
        <Button kind="primary" tabIndex={0} type="submit">
          Submit
        </Button>
      </Form>
    </>
  );
};

export default ContactForm;
