import React from "react";
import { Helmet } from "react-helmet";
import { graphql, useStaticQuery } from "gatsby";

const useMetadata = () => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
          description
          keywords
          isSearchEnabled
          homepageTheme
          interiorTheme
          navigationStyle
          lang
        }
      }
    }
  `);

  return data.site.siteMetadata;
};

const Meta = ({ pageTitle, pageDescription, pageKeywords, titleType }) => {
  const { title, description, keywords, lang } = useMetadata();

  const getPageTitle = () => {
    switch (titleType) {
      case "page":
        // use site title for fallback
        return pageTitle || title;
      case "site":
        return title;
      case "append":
        return `${title}${pageTitle ? ` – ${pageTitle}` : ""}`;
      case "prepend":
        return `${pageTitle ? `${pageTitle} – ` : ""}${title}`;
      default:
        return null;
    }
  };

  return (
    <Helmet
      title={getPageTitle()}
      meta={[
        {
          name: "description",
          content: pageDescription || description,
        },
        {
          name: "keywords",
          content: pageKeywords || keywords,
        },
        {
          name: "twitter:card",
          content: "summary_large_image",
        },
        {
          name: "twitter:site",
          content: "magnuscode.com",
        },
        {
          name: "twitter:title",
          content: getPageTitle(),
        },
        {
          name: "twitter:description",
          content: pageDescription || description,
        },
        {
          name: "twitter:image",
          content:
            "https://res.cloudinary.com/dycxbptw4/image/upload/v1613126549/magnuscode%20website/main_nydbx4.jpg",
        },
        {
          property: "og:title",
          content: getPageTitle(),
        },
        {
          property: "og:description",
          content: pageDescription || description,
        },
        {
          property: "og:image",
          content:
            "https://res.cloudinary.com/dycxbptw4/image/upload/v1613126549/magnuscode%20website/main_nydbx4.jpg",
        },
      ]}
    >
      <html lang={lang} />
      <script
        async
        defer
        data-domain="magnuscode.com"
        src="https://plausible.magnoz.net/js/plausible.js"
      ></script>
    </Helmet>
  );
};

export default Meta;
