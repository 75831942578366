import React from 'react';
import Switcher from 'gatsby-theme-carbon/src/components/Switcher/Switcher';
import {
  SwitcherDivider,
  SwitcherLink,
} from 'gatsby-theme-carbon/src/components/Switcher/Switcher';

const DefaultChildren = () => (
  <>
    <SwitcherDivider>Expertise</SwitcherDivider>
    <SwitcherLink href="/services/automation-iot">
      Real Time Monitoring Solution
    </SwitcherLink>
    <SwitcherLink href="/services/web-design">
      Web Design and Development
    </SwitcherLink>
    <SwitcherDivider>Coming Soon</SwitcherDivider>
    <SwitcherLink href="/" isInternal disabled>
      Magnus Design System
    </SwitcherLink>
    <SwitcherLink href="/" disabled>
      IoT Edge
    </SwitcherLink>
    <SwitcherLink href="/" isInternal disabled>
      Authorization Server
    </SwitcherLink>
    <SwitcherDivider>Privacy</SwitcherDivider>
    <SwitcherLink href="/privacy">Privacy Policy</SwitcherLink>
    <SwitcherLink href="/terms">Terms of Use</SwitcherLink>
    <SwitcherDivider>Community</SwitcherDivider>
    {/* <SwitcherLink href="https://blog.magnuscode.com" target="_blank">
      Magnus Blog
    </SwitcherLink> */}
    <SwitcherLink
      href="https://twitter.com/magnus_code"
      target="_blank"
      rel="noopener"
    >
      Twitter
    </SwitcherLink>
    <SwitcherLink
      href="https://www.instagram.com/magnus_code/"
      target="_blank"
      rel="noopener"
    >
      Instagram
    </SwitcherLink>
  </>
);

const CustomSwitcher = () => {
  return (
    <Switcher>
      <DefaultChildren />
    </Switcher>
  );
};
export default CustomSwitcher;
