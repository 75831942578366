import React from 'react';
import ResourceLinks from 'gatsby-theme-carbon/src/components/LeftNav/ResourceLinks';

const links = [
  // {
  //   title: 'Magnus Blog',
  //   href: 'https://blog.magnuscode.com',
  // },
  {
    title: 'Twitter',
    href: 'https://twitter.com/magnus_code',
  },
  {
    title: 'Instagram',
    href: 'https://www.instagram.com/magnus_code/',
  },
  {
    title: 'Privacy',
    href: '/privacy',
  },
  {
    title: 'Terms of Use',
    href: '/terms',
  },
];

// shouldOpenNewTabs: true if outbound links should open in a new tab
const CustomResources = () => <ResourceLinks shouldOpenNewTabs links={links} />;

export default CustomResources;
