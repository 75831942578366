import React from "react";
import { Column } from "gatsby-theme-carbon";

import HomepageTemplate from "gatsby-theme-carbon/src/templates/Homepage";
import { leadspaceText, leadspaceImage } from "./Homepage.module.scss";

import "./_homepage.scss";

import BannerHeroDesktop from "../../images/homepage/home-desktop-lead-space.svg";
import BannerHeroMobile from "../../images/homepage/home-mobile-lead-space.svg";
import HomePageCTA from "../../components/HomePageCTA/HomePageCTA";

const LeadSpaceText = () => (
  <p className={leadspaceText}>
    {" "}
    <strong>Design</strong> and <strong>development</strong> studio.
  </p>
);

const customProps = {
  Banner: (
    <>
      <section className="hero-bg">
        <div className="bx--grid">
          <div className="bx--row">
            <Column colLg={11} colMd={8}>
              {LeadSpaceText()}
            </Column>
            <Column colLg={11} colMd={8} className={leadspaceImage}>
              <picture>
                <source
                  media="(min-width: 1056px)"
                  srcset={`${BannerHeroDesktop}`}
                />
                <source
                  media="(min-width: 672px)"
                  srcset={`${BannerHeroDesktop}`}
                />
                <source
                  media="(min-width: 0px)"
                  srcset={`${BannerHeroMobile}`}
                />
                <img
                  class="gatsby-resp-image-image"
                  src={BannerHeroDesktop}
                  alt="Magnus Code"
                  loading="lazy"
                  width="100%"
                  height="100%"
                />
              </picture>
            </Column>
          </div>
        </div>
      </section>
    </>
  ),
  FirstCallout: <HomePageCTA />,
  SecondCallout: "",
};

// spreading the original props gives us props.children (mdx content)
function ShadowedHomepage(props) {
  return <HomepageTemplate {...props} {...customProps} />;
}

export default ShadowedHomepage;
