import React from "react";
import ContentBlockCards from "@carbon/ibmdotcom-react/es/components/ContentBlockCards/ContentBlockCards";
import cards from "./cards.json";

import "./ServiceContentBlock.scss";

function ServiceContentBlock() {
  return (
    <ContentBlockCards
      heading={"Specialist in Building Digital Platforms"}
      cards={cards["Service"]}
    />
  );
}

export default ServiceContentBlock;
