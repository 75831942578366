import React from "react";

import { ContentBlockSegmented } from "@carbon/ibmdotcom-react";
import "./ContentBlock.scss";

const ContentBlock = () => (
  <div>
    <ContentBlockSegmented
      heading="All inclusive web designing and development solutions."
      copy="Our web development solutions covers all your website needs from designing to a fully customised and functional website. We focus on creating highly optimised, speedy, and responsive web pages so that your business rank well for a variety of search terms and attract a steady stream of new customers. All Magnus Code websites are developed with built in mobile integration to improve both the experience of visitors and SEO Rankings."
      mediaType="image"
      mediaData={{
        heading: "Control system design with PLC.",
        image: {
          sources: [
            {
              src:
                "https://fpoimg.com/672x672?text=16:9&bg_color=ee5396&text_color=161616",
              breakpoint: 320,
            },
            {
              src:
                "https://fpoimg.com/400x225?text=16:9&bg_color=ee5396&text_color=161616",
              breakpoint: 400,
            },
            {
              src:
                "https://fpoimg.com/672x378?text=16:9&bg_color=ee5396&text_color=161616",
              breakpoint: 672,
            },
          ],
          alt: "Image alt text",
          defaultSrc:
            "https://fpoimg.com/672x378?text=16:9&bg_color=ee5396&text_color=161616",
        },
      }}
      items={[
        {
          heading: "Adaptable,Robust, Scalable",
          copy:
            "Building website through Magnus Code ensures your website works quickly, correctly and as expected. Built to web standards and tested regularly for issues with speed or functionality, every page is fast, functional and secure. We create future proof websites through our continuous development and maintenance solutions to keep up with evolution of our clients' requirement.",
        },
        {
          heading: "Lorem ipsum dolor sit amet.",
          copy:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean et ultricies est. Mauris iaculis eget dolor nec hendrerit. Phasellus at elit sollicitudin, sodales nulla quis, consequat libero. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean et ultricies est. Mauris iaculis eget dolor nec hendrerit.",
          image: {
            sources: [
              {
                src:
                  "https://fpoimg.com/672x672?text=16:9&bg_color=ee5396&text_color=161616",
                breakpoint: 320,
              },
              {
                src:
                  "https://fpoimg.com/400x225?text=16:9&bg_color=ee5396&text_color=161616",
                breakpoint: 400,
              },
              {
                src:
                  "https://fpoimg.com/672x378?text=16:9&bg_color=ee5396&text_color=161616",
                breakpoint: 672,
              },
            ],
            alt: "Image alt text",
            defaultSrc:
              "https://fpoimg.com/672x378?text=16:9&bg_color=ee5396&text_color=161616",
          },
        },
      ]}
    />
  </div>
);

export default ContentBlock;
