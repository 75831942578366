import React from 'react';
import Footer from 'gatsby-theme-carbon/src/components/Footer';

import { logo } from './Footer.module.scss';
import { StaticImage } from 'gatsby-plugin-image';

const Content = ({ buildTime }) => (
  <>
    <p>
      Have questions? Email us or Slack <strong>#magnus-code</strong> (Internal
      Magnus Code users only).
    </p>
    <p>Last updated on {buildTime}</p>
    <p>Copyright © {new Date().getFullYear()} MAGNUS CODE SDN BHD</p>
    <StaticImage
      src="../../images/EIC.png"
      alt="Magnus Code SDN BHD - EIC Member"
    />
  </>
);

const links = {
  firstCol: [
    { href: '/privacy', linkText: 'Privacy' },
    { href: '/terms', linkText: 'Terms of use' },
    { href: '/', linkText: 'MAGNUSCODE.com' },
  ],
  secondCol: [
    { href: '/services', linkText: 'Services' },
    { href: '/contact', linkText: 'Contact' },
  ],
};

const MagnusLogo = () => (
  <svg className={logo} height="32" xmlns="http://www.w3.org/2000/svg">
    <defs></defs>
    <title>Asset 10</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_3" data-name="Layer 3">
        <polygon
          class="cls-1"
          points="51 8.16 47.5 14.41 44 14.41 40.5 8.16 40.5 17.91 36 17.91 36 0.41 40.75 0.41 45.75 9.66 50.75 0.41 55.5 0.41 55.5 17.91 51 17.91 51 8.16"
        />
        <path
          class="cls-1"
          d="M64.13,17.91H59.25L65.88.41h5l6.62,17.5H72.63L71.5,14.79H65.25Zm2.25-6.37h4l-2-5.88Z"
        />
        <path
          class="cls-1"
          d="M95.75,17.54A103.46,103.46,0,0,1,85,18.16,4.91,4.91,0,0,1,81.4,17a4.88,4.88,0,0,1-1.15-3.6v-8A5.22,5.22,0,0,1,81.44,1.6,5.22,5.22,0,0,1,85.25.41h9v3.5h-8a1.33,1.33,0,0,0-1.5,1.5v8a1.19,1.19,0,0,0,.34.91,1.21,1.21,0,0,0,.91.34q2.88,0,5.25-.12V10.91H88v-3h7.75Z"
        />
        <polygon
          class="cls-1"
          points="116 17.91 111.25 17.91 105.25 7.41 105.25 17.91 100.75 17.91 100.75 0.41 105.5 0.41 111.5 10.91 111.5 0.41 116 0.41 116 17.91"
        />
        <path
          class="cls-1"
          d="M132.75,13.16a1.33,1.33,0,0,1-1.5,1.5H127.5a1.33,1.33,0,0,1-1.5-1.5v-7h-4.5v7A5.18,5.18,0,0,0,122.69,17a5.22,5.22,0,0,0,3.81,1.19h5.75A5.22,5.22,0,0,0,136.06,17a5.22,5.22,0,0,0,1.19-3.81v-7h-4.5Zm0-12.75V4.92h4.5V.41ZM126,.41h-4.5V4.92H126Z"
        />
        <path
          class="cls-1"
          d="M142.22,14.29q6.85.38,9.28.37a1.21,1.21,0,0,0,.91-.34,1.19,1.19,0,0,0,.34-.91V11.16H147A5.18,5.18,0,0,1,143.19,10,5.22,5.22,0,0,1,142,6.16V5.41a5.22,5.22,0,0,1,1.19-3.81A5.22,5.22,0,0,1,147,.41h8.78v3.5H148a1.33,1.33,0,0,0-1.5,1.5v.5a1.33,1.33,0,0,0,1.5,1.5h4.5a5,5,0,0,1,3.6,1.15,4.91,4.91,0,0,1,1.15,3.6v1.25A4.88,4.88,0,0,1,156.1,17a4.91,4.91,0,0,1-3.6,1.15c-.85,0-1.75,0-2.7-.05l-2.3-.1c-1.7-.08-3.46-.2-5.28-.35Z"
        />
        <rect class="cls-1" x="161.25" y="13.4" width="4.5" height="4.51" />
        <polygon
          class="cls-1"
          points="8.98 0 26.93 0 17.95 17.95 0 17.95 8.98 0"
        />
      </g>
    </g>
  </svg>
);

const CustomFooter = () => (
  <Footer links={links} Content={Content} Logo={MagnusLogo} />
);

export default CustomFooter;
