import defaultComponents from "gatsby-theme-carbon/src/components/MDXProvider/defaultComponents";
import ServiceContentBlock from "../../../components/ServiceContentBlock/ServiceContentBlock";
import ContactForm from "../../../components/ContactForm/ContactForm";
import ContentBlock from "../../../components/ContentBlock/ContentBlock";

export default {
  ...defaultComponents,
  ServiceContentBlock,
  ContactForm,
  ContentBlock,
};
