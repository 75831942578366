import React from 'react';
import CTASection from '@carbon/ibmdotcom-react/es/components/CTASection/CTASection';
import './HomePageCTA.scss';

const ctaProps = {
  style: 'button',
  type: 'local',
  buttons: [
    {
      type: 'local',
      copy: ['View services'],
      href: '/services',
    },
    {
      type: 'local',
      copy: ['Contact Us'],
      href: '/contact',
    },
  ],
};

const contentBlockProps = {
  heading: 'Build. Launch. Grow.',
  copy: `Malaysian digital agency providing professional web development, IoT, mechanical design and IT services. Want to discuss your options with an expert? `,
};

function HomePageCTA() {
  return (
    <div className='bx--grid'>
      <CTASection {...contentBlockProps} cta={ctaProps} />
    </div>
  );
}

export default HomePageCTA;
