// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-certifications-mdx": () => import("./../../../src/pages/certifications.mdx" /* webpackChunkName: "component---src-pages-certifications-mdx" */),
  "component---src-pages-contact-mdx": () => import("./../../../src/pages/contact.mdx" /* webpackChunkName: "component---src-pages-contact-mdx" */),
  "component---src-pages-gallery-index-mdx": () => import("./../../../src/pages/gallery/index.mdx" /* webpackChunkName: "component---src-pages-gallery-index-mdx" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-privacy-mdx": () => import("./../../../src/pages/privacy.mdx" /* webpackChunkName: "component---src-pages-privacy-mdx" */),
  "component---src-pages-services-automation-iot-mdx": () => import("./../../../src/pages/services/automation-iot.mdx" /* webpackChunkName: "component---src-pages-services-automation-iot-mdx" */),
  "component---src-pages-services-game-assets-mdx": () => import("./../../../src/pages/services/game-assets.mdx" /* webpackChunkName: "component---src-pages-services-game-assets-mdx" */),
  "component---src-pages-services-index-mdx": () => import("./../../../src/pages/services/index.mdx" /* webpackChunkName: "component---src-pages-services-index-mdx" */),
  "component---src-pages-services-logo-mdx": () => import("./../../../src/pages/services/logo.mdx" /* webpackChunkName: "component---src-pages-services-logo-mdx" */),
  "component---src-pages-services-mechanical-design-mdx": () => import("./../../../src/pages/services/mechanical-design.mdx" /* webpackChunkName: "component---src-pages-services-mechanical-design-mdx" */),
  "component---src-pages-services-mobile-mdx": () => import("./../../../src/pages/services/mobile.mdx" /* webpackChunkName: "component---src-pages-services-mobile-mdx" */),
  "component---src-pages-services-product-visualization-mdx": () => import("./../../../src/pages/services/product-visualization.mdx" /* webpackChunkName: "component---src-pages-services-product-visualization-mdx" */),
  "component---src-pages-services-web-design-mdx": () => import("./../../../src/pages/services/web-design.mdx" /* webpackChunkName: "component---src-pages-services-web-design-mdx" */),
  "component---src-pages-terms-mdx": () => import("./../../../src/pages/terms.mdx" /* webpackChunkName: "component---src-pages-terms-mdx" */),
  "component---src-pages-work-mdx": () => import("./../../../src/pages/work.mdx" /* webpackChunkName: "component---src-pages-work-mdx" */)
}

